<template>
    <div class="page-auth">
        <div class="register-page">
            <div class="hidden bg-spect lg:flex flex-col items-center justify-evenly">
                <img class="" src="../../assets/auth/images/spec-brand-white.svg"
                        alt="cyberforceacademy logo"  >
                <img class="" src="../../assets/auth/images/icon-auth-banner.svg"
                alt="cyberforceacademy logo" >

                <p class="text-white text-2xl text-center">Placez l’humain au coeur de votre Stratégie
                    de <span class="text-accent">Cyberdéfense</span></p>
                
            </div>
            <div class="content-form flex items-center justify-center py-4 bg-back">
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 ">
                    
                    <div class="grid gap-2 text-center">
                        <h1 class="text-4xl font-[500] text-spect">
                            Invitation sur Spectorly
                        </h1>
                        <p class="text-balance">
                            Remplissez les informations requises pour accepter l'invitation de l'organisation
                        </p>
                    </div>
                    
                    <form @submit.prevent="handleSubmit" class="space-y-6">
                        <!-- Name -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Nom</label>
                            <input
                            type="text"
                            v-model="formData.lastName"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            :class="{ 'ui-input--error': errors.lastName }"
                            placeholder="Entrez votre nom"
                            />
                            <p v-if="errors.lastName" class="text-red-500 text-sm mt-1">{{ errors.lastName }}</p>

                        </div>

                        <!-- First Name -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Prénom</label>
                            <input
                            type="text"
                            v-model="formData.firstName"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            placeholder="Entrez votre prénom"
                            :class="{ 'ui-input--error': errors.firstName }"
                            />
                            <p v-if="errors.firstName" class="text-red-500 text-sm mt-1">{{ errors.firstName }}</p>

                        </div>

                        <!-- Phone -->
                        
                        <div class="">
                            <label for="telephone" class="block font-medium text-gray-700 mb-1">Telephone</label>
                            <div class="relative">
                                <country-code class="country-dial absolute top-0 pl-2 country-input" @code="changeCode" />
                                <input id="telephone" type="text" placeholder="Entrez votre numero" required class="input input-bordered w-full tel-input ui-input" v-model="formData.phone" :class="{ 'ui-input--error': errors.phone }" />
                            </div>
                            <p v-if="errors.phone" class="text-red-500 text-sm mt-1">{{ errors.phone }}</p>
                        </div>
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Mot de passe</label>
                            <div class="relative">
                                <input
                                :type="toggle.password ? 'text' : 'password'"
                                v-model="formData.password"
                                class="w-full px-4 py-2 input input-bordered ui-input"
                                placeholder="mot de passe"
                                :class="{ 'ui-input--error': errors.password }"
                                />
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = false" v-if="toggle.password" ><i class="fa fa-eye-slash"></i></span>
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = true" v-if="!toggle.password" ><i class="fa fa-eye"></i></span>
                            </div>
                            <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
                        </div>
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Confirmez mot de passe</label>
                            
                            <div class="relative">
                                <input
                                :type="toggle.confirmPassword ? 'text' : 'password'"
                                v-model="formData.confirmPassword"
                                class="w-full px-4 py-2 input input-bordered ui-input"
                                placeholder="mot de passe"
                                :class="{ 'ui-input--error': errors.confirmPassword }"
                                />
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.confirmPassword = false" v-if="toggle.confirmPassword" ><i class="fa fa-eye-slash"></i></span>
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.confirmPassword = true" v-if="!toggle.confirmPassword" ><i class="fa fa-eye"></i></span>
                            </div>
                            <p v-if="errors.confirmPassword" class="text-red-500 text-sm mt-1">{{ errors.confirmPassword }}</p>
                        </div>
                        
                        

                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full bg-spect text-white py-3 rounded-lg hover:bg-opacity-90 transition-colors flex items-center justify-center gap-2 btn-disable " :disabled="isInvalid"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                Accepter
                            </span>
                        </button>

                        

                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <div class="toast toast-end my-toast" v-if="isAlert">
            <div class="alert alert-info toast-ctn--info">
                <span>{{ alertMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import countryCode from '../../components/shared/input/countryCode.vue';
import axios from "axios";

export default {
    name: 'NewAcceptInviteView',
    components: {
        countryCode
    },
    data () {
        return {
            pub: null,
            toggle: {
                password: false,
                confirmPassword: false,
            },
            formData: {
                lastName: '',
                name: '',
                phone: '',
                password: '',
                confirmPassword: '',
            },
            errors: {
                lastName: null,
                name: null,
                password: null,
                confirmPassword: null,
            },
            isSubmit: false,
            isAlert: false,
            alertMessage: "New message",
            isInvalid: false,
            dialCode: ""
        }
    },
    computed : {
        username () {
            if (this.$route.query.username) {
                return this.decodeBase64String(this.$route.query.username)
            }
            return "John"
        }
    },
    methods: {
        decodeBase64String(encodedString) {
            const decodedString = decodeURIComponent(atob(encodedString).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return (decodedString !== 'None') ? decodedString : '';
        },
        changeCode (country) {
            
            this.dialCode = '+' + country[2] + ''
            //this.formData.country = country[0];
            
            //console.log(country)
            
        },
        async handleSubmit () {
            if(!this.$route.params.uid || !this.$route.params.token) {
                this.emitAlert('URL Invalid', 6000)
                return
            }
            if (this.validateForm()) {
                console.log("continue")
                this.isSubmit = true
                this.isInvalid = true

                let payload = {
                    firstname:this.formData.firstName,
                    lastname:this.formData.lastName,
                    password: this.formData.password,
                    phone: this.formData.phone,
                }
                console.log(payload)
                //return
                //eslint-disable-next-line
                await axios.put(`${process.env.VUE_APP_BASE_URL}organisations/accept/invite/${this.$route.params.uid}/${this.$route.params.token}/`, payload
                )
                .then(response => {
                    console.log(response)
                    if(response.status == 200 || response.status == 201) {
                        this.emitAlert("Super ! invitation acceptée, vérifiez votre boîte de réception e-mail", 2000)
                        this.$router.push({
                            name: "Login",
                        });
                        //this.$emit('activated')
                
                    }
                    else {
                        this.emitAlert("Une erreur s'est produite", 5000)
                    }
                    this.isSubmit = false
                    this.isInvalid = false

                })
                .catch(error => {
                    console.log(error)
                    localStorage.removeItem('token')
                    if (error.response) {
                        let message = error.response?.data?.message ? error.response?.data?.message : "Une erreure s'est produite"
                        this.emitAlert(message, 5000)
                    }
                    else if (error.request) {
                        this.emitAlert("Une erreure s'est produite", 5000)
                        
                    }
                    else {
                        //this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                        this.emitAlert("Une erreure s'est produite", 5000)
                    }
                    this.isSubmit = false
                    this.isInvalid = false
                })
            }
        },
        validateForm() {
            // Add validation logic for step 2
            let isValid = true
            this.errors = {
                
                lastName: null,
                firstName: null,
                phone: null,
                
                password : null,
                confirmPassword : null
            }
            
            /*if (!this.formData.email || this.formData.email.trim() == "" ) {
                this.errors.email = 'Le champ email est obligatoire.'
                isValid = false
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.formData.email)) {
                this.errors.email = 'L\'adresse email n\'est pas valide.'
                isValid = false
            }*/
            if (!this.formData.lastName || this.formData.lastName.trim() == "") {
                this.errors.lastName = 'Le champ nom est obligatoire.'
                isValid = false
            }

            if (!this.formData.firstName || this.formData.firstName.trim() == "") {
                this.errors.firstName = 'Le champ prenom est obligatoire.'
                isValid = false
            }
            if (!this.formData.phone || this.formData.phone.trim() == "") {
                this.errors.phone = 'Le champ telephone est obligatoire.'
                isValid = false
            } else if (!this.isNumeric(this.formData.phone)) {
                this.errors.phone = 'Le champ telephone doit contenir uniquement des chiffres.'
                isValid = false
            }
            if (this.formData.password.length < 12) {
                this.errors.password = 'Mot de passe de 12 caractere mininum et contenant au moins un  caractere special'
                isValid = false
            }
            if (this.formData.password != this.formData.confirmPassword) {
                this.errors.confirmPassword = 'Les mots de passe ne correspondent pas.'
                isValid = false
            }
            return isValid
        },
        isNumeric(phoneNumber) {
            return /^[0-9]+$/.test(phoneNumber);
        },
        emitAlert(text, duration) {
            this.alertMessage = text 
            this.isAlert = true;
            setTimeout (()=> {
                this.isAlert = false
            }, duration)
        }
    },
    mounted () {
        if(!this.$route.params.uid || !this.$route.params.token)  {
            this.isInvalid = true
            this.alertMessage = "URL invalide" 
            this.isAlert = true;
            setTimeout (()=> {
                this.isAlert = false
            }, 3000)
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/tailwinds.scss";
    
</style>