<template>
    <div class="page-auth">
        <div class="register-page">
            <div class="hidden bg-spect lg:flex flex-col items-center justify-evenly">
                <img class="" src="../../assets/auth/images/spec-brand-white.svg"
                        alt="cyberforceacademy logo"  >
                <img class="" src="../../assets/auth/images/icon-auth-banner.svg"
                alt="cyberforceacademy logo" >

                <p class="text-white text-2xl text-center w-3/4 xl:w-full ">Placez l’humain au coeur de votre Stratégie
                    de <span class="text-accent">Cyberdéfense</span></p>
                <!--<img
                    src="#"
                    alt="Image"
                    width="1920"
                    height="1080"
                    class="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
                >-->
            </div>
            
            <div class="content-form flex items-center justify-center py-4 bg-back">
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " >
                    
                    <div class="grid gap-2 text-center">
                        <h1 class="text-4xl font-[500] text-spect">
                            Réinitialisation de mot de passe
                        </h1>
                        <p class="text-balance">
                            Définissez ici un nouveau mot de passe pour vous connecter à votre compte.
                        </p>
                    </div>
                    <form  @submit.prevent="handleSubmit" class="grid gap-3 px-4">
                        
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Mot de passe</label>
                            <div class="relative">
                                <input
                                :type="toggle.password ? 'text' : 'password'"
                                v-model="formData.password"
                                class="w-full px-4 py-2 input input-bordered ui-input"
                                placeholder="mot de passe"
                                :class="{ 'ui-input--error': errors.password }"
                                />
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = false" v-if="toggle.password" ><i class="fa fa-eye-slash"></i></span>
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.password = true" v-if="!toggle.password" ><i class="fa fa-eye"></i></span>
                            </div>
                            <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
                        </div>
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Confirmez mot de passe</label>
                            
                            <div class="relative">
                                <input
                                :type="toggle.confirmPassword ? 'text' : 'password'"
                                v-model="formData.confirmPassword"
                                class="w-full px-4 py-2 input input-bordered ui-input"
                                placeholder="mot de passe"
                                :class="{ 'ui-input--error': errors.confirmPassword }"
                                />
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.confirmPassword = false" v-if="toggle.confirmPassword" ><i class="fa fa-eye-slash"></i></span>
                                <span class="absolute right-2 top-3 cursor-pointer" @click="toggle.confirmPassword = true" v-if="!toggle.confirmPassword" ><i class="fa fa-eye"></i></span>
                            </div>
                            <p v-if="errors.confirmPassword" class="text-red-500 text-sm mt-1">{{ errors.confirmPassword }}</p>
                        </div>


                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full flex items-center justify-center gap-3 bg-spect text-white py-3 rounded-6 hover:bg-opacity-90 transition-colors" :disabled="isInvalid || isSubmit"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                Réinitialiser
                            </span>
                        </button>
                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                    
                </div>
                <!-- <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " v-else>
                    
                    <div class="flex flex-col justify-center items-center gap-3 ">
                        <p class="text-3xl w-full text-center">
                            Inscription terminee
                        </p>
                        <video width="256" height="256" preload="none" style="background: transparent url('https://cdn-icons-png.flaticon.com/512/8717/8717946.png') 50% 50% / fit no-repeat;" autoplay="autoplay" loop="true" muted="muted" playsinline="" class="" >
                            <source src="https://cdn-icons-mp4.flaticon.com/512/8717/8717946.mp4" type="video/mp4">
                        </video>
                        <label class="w-full text-center">Vérifiez votre boîte email pour activer votre compte. Merci!</label>
                        <div class="text-center">
                            <span class="text-gray-600">J'ai pas reçu le mail&nbsp; </span>
                            <a  class="text-accent cursor-pointer" @click="resentActivationMail" >Renvoyer le mail d'activation</a>
                        </div>
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </div>
                    
                    
                </div> -->
            </div>
        </div>
        <div class="toast toast-end my-toast" v-if="isAlert">
            <div class="alert alert-info toast-ctn--info">
                <span>{{ alertMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>

//import countryCode from '../../components/shared/input/countryCode.vue';
import axios from "axios";

export default {
    name: "NewPasswordReset",
    components: {
        //countryCode
    },
    setup() {
        
    },
    data() {
        return {
            dialCode:'',
            isCodeStep: false,
            activityOptions: [],
            toggle: {
                password: false,
                confirmPassword: false,
            },
            formData: {
                password: '',
                confirmPassword: '',
            },
            errors: {
                password: null,
                confirmPassword: null,
            },
            isSubmit: false,
            isReset: false,
            isAlert: false,
            alertMessage: "New message",
            isInvalid: false,
            staticService: [],
            service_id: "",
            serviceoptions:[]
        }
    },
    methods: {
        
        
        async handleSubmit () {
            // Handle form submission
            if (this.validateStep2()) {
                //let phoneNumber = this.formData.phone
                this.isSubmit = true
                //console.log(this.formData, phoneNumber)
                
                await axios.post(`${process.env.VUE_APP_BASE_URL}accounts/reset-password/${this.$route.query.token}/${this.$route.query.uid}`,
                    {
                        password: this.formData.password,
                        password2: this.formData.confirmPassword
                    }
                )
                .then(res => {
                    console.log(res)
                    if(res.status == 200) {
                        //this.isReset = true
                        //localStorage.setItem('guest_email', this.formData.email);
                        this.emitAlert("Super ! mot de passe changé avec succès.", 5000)
                        let a = document.createElement('a');
                        a.href="/login"
                        a.click()
                        a.remove()
                    }
                })
                .catch(err => {
                    console.log(err)
                    localStorage.removeItem('token')
                    this.isSubmit = false
                    if (err.response) {
                        
                        this.errors.password = " Définissez un mot de passe d'au moins 12 caractères contenant au moins un chiffre et un caractère spécial."
                    }
                    else if (err.request) {
                        
                        this.emitAlert("Une erreur s'est produite. Veuillez rechargez la page")
                    }
                    else {
                        //this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                        this.emitAlert("Une erreur s'est produite. Veuillez rechargez la page")

                    }
                    this.isSubmit = false
                })
                
            }
        },
        
        validateStep2() {
            
            this.errors.password = null
            this.errors.confirmPassword = null
            const regex = /^(?=.*\d)(?=.*[\W_]).+$/;
            let isValid = true

            if (this.formData.password.length < 12 || !regex.test(this.formData.password)) {
                this.errors.password = 'Mot de passe de 12 caractere mininum et contenant au moins un  caractere special'
                isValid = false
            }
            if (this.formData.password != this.formData.confirmPassword) {
                this.errors.confirmPassword = 'Les mots de passe ne correspondent pas.'
                isValid = false
            }
            return isValid
        },
        
        isNumeric(phoneNumber) {
            return /^[0-9]+$/.test(phoneNumber);
        },
        
        emitAlert(text, duration) {
            this.alertMessage = text 
            this.isAlert = true;
            setTimeout (()=> {
                this.isAlert = false
            }, duration)
        }
    },
    mounted () {
        if(!this.$route.query.uid || !this.$route.query.token)  {
            //this.displayError('Oups ! URL invalide')
            this.isInvalid = true
            this.emitAlert("URL invalide", 3000)
        }
    },
    watch: {
        
    }

}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/tailwinds.scss";
    
</style>