<template>
    <div class="page-auth">
        <div class="register-page">
            <div class="hidden bg-spect lg:flex flex-col items-center justify-evenly">
                <img class="" src="../../assets/auth/images/spec-brand-white.svg"
                        alt="cyberforceacademy logo"  >
                <img class="" src="../../assets/auth/images/icon-auth-banner.svg"
                alt="cyberforceacademy logo" >

                <p class="text-white text-2xl text-center w-3/4 xl:w-full ">Placez l’humain au coeur de votre Stratégie
                    de <span class="text-accent">Cyberdéfense</span></p>
                <!--<img
                    src="#"
                    alt="Image"
                    width="1920"
                    height="1080"
                    class="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
                >-->
            </div>
            
            <div class="content-form flex items-center justify-center py-4 bg-back">
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " v-if="!isRegister">
                    
                    <div class="grid gap-2 text-center">
                        <h1 class="text-4xl font-[500] text-spect">
                            Inscription
                        </h1>
                        <p class="text-balance">
                            Entrez votre code entreprise d'inscription
                        </p>
                    </div>
                    <form v-if="!isCodeStep" @submit.prevent="verifyCode" class="grid gap-3 px-4">
                        
                        <!-- Code -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Nom</label>
                            <input
                            type="text"
                            v-model="formData.code"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            :class="{ 'ui-input--error': errors.code }"
                            placeholder="Entrez votre code d'entreprise"
                            />
                            <p v-if="errors.code" class="text-red-500 text-sm mt-1">{{ errors.code }}</p>

                        </div>


                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full flex items-center justify-center gap-3 bg-spect text-white py-3 rounded-6 hover:bg-opacity-90 transition-colors" :disabled="isInvalid || isSubmit"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                Suivant
                            </span>
                        </button>
                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                    <form v-else @submit.prevent="handleSubmit" class="grid gap-3 px-4">
                        <!-- Service Type -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Service</label>
                            <select
                            v-model="formData.service"
                            class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-[#d8d8d8] focus:border-transparent"
                            >
                            <option value="">Selectionner un service</option>
                            <option :value="activity.value" v-for="activity in serviceoptions" :key="activity.value" >{{ activity.text }}</option>
                            
                            </select>
                            <p v-if="errors.service" class="text-red-500 text-sm mt-1">{{ errors.service }}</p>
                        </div>

                        <!-- Email -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Email</label>
                            <input
                            type="email"
                            v-model="formData.email"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            :class="{ 'ui-input--error': errors.email }"
                            placeholder="Entrez votre adresse email"
                            />
                            <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email }}</p>
                        </div>

                        <!-- Name -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Nom</label>
                            <input
                            type="text"
                            v-model="formData.lastName"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            :class="{ 'ui-input--error': errors.lastName }"
                            placeholder="Entrez votre nom"
                            />
                            <p v-if="errors.lastName" class="text-red-500 text-sm mt-1">{{ errors.lastName }}</p>

                        </div>

                        <!-- First Name -->
                        <div>
                            <label class="block font-medium text-gray-700 mb-1">Prénom</label>
                            <input
                            type="text"
                            v-model="formData.firstName"
                            class="w-full px-4 py-2 input input-bordered ui-input"
                            placeholder="Entrez votre prénom"
                            :class="{ 'ui-input--error': errors.firstName }"
                            />
                            <p v-if="errors.firstName" class="text-red-500 text-sm mt-1">{{ errors.firstName }}</p>

                        </div>

                        <!-- Phone -->
                        
                        <div class="">
                            <label for="telephone" class="block font-medium text-gray-700 mb-1">Telephone</label>
                            <div class="relative">
                                <country-code class="country-dial absolute top-0 pl-2 country-input" @code="changeCode" />
                                <input id="telephone" type="text" placeholder="Entrez votre numero" required class="input input-bordered w-full tel-input ui-input" v-model="formData.phone" :class="{ 'ui-input--error': errors.phone }" />
                            </div>
                            <p v-if="errors.phone" class="text-red-500 text-sm mt-1">{{ errors.phone }}</p>
                        </div>
                        <!-- Terms -->
                        <div class="flex items-center justify-center">
                            <input
                            type="checkbox"
                            v-model="formData.acceptTerms"
                            class="form-checkbox text-accent"
                            />
                            <span class="ml-2 text-sm text-gray-600">
                            J'accepte les 
                            <a href="#" class="text-accent">CGU</a> et 
                            <a href="#" class="text-accent">politiques de confidentialités</a>
                            </span>
                        </div>

                        <!-- Submit Button -->
                        <button
                            type="submit"
                            class="w-full flex items-center justify-center gap-3 bg-spect text-white py-3 rounded-6 hover:bg-opacity-90 transition-colors" :disabled="isInvalid || isSubmit"
                        >
                            <span class="loading loading-spinner" v-if="isSubmit" ></span>
                            <span>
                                Terminer mon inscription
                            </span>
                        </button>
                        <!-- Login Link -->
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </form>
                </div>
                <div class="mx-auto grid w-3/4 gap-6 bg-white py-8 px-3 rounded-15 " v-else>
                    
                    <div class="flex flex-col justify-center items-center gap-3 ">
                        <p class="text-3xl w-full text-center">
                            Inscription terminee
                        </p>
                        <video width="256" height="256" preload="none" style="background: transparent url('https://cdn-icons-png.flaticon.com/512/8717/8717946.png') 50% 50% / fit no-repeat;" autoplay="autoplay" loop="true" muted="muted" playsinline="" class="" >
                            <source src="https://cdn-icons-mp4.flaticon.com/512/8717/8717946.mp4" type="video/mp4">
                        </video>
                        <label class="w-full text-center">Vérifiez votre boîte email pour activer votre compte. Merci!</label>
                        <div class="text-center">
                            <span class="text-gray-600">J'ai pas reçu le mail&nbsp; </span>
                            <a  class="text-accent cursor-pointer" @click="resentActivationMail" >Renvoyer le mail d'activation</a>
                        </div>
                        <div class="text-center text-sm">
                            <span class="text-gray-600">Vous avez un compte? </span>
                            <a href="/" class="text-accent">Connectez-vous ici</a>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div class="toast toast-end my-toast" v-if="isAlert">
            <div class="alert" :class="{'alert-info toast-ctn--info': isAlertSuccess, 'alert-error toast-ctn--error': !isAlertSuccess}" >
                <span>{{ alertMessage }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import countryCode from '../../components/shared/input/countryCode.vue';
import axios from "axios";

export default {
    name: "NewRegisterWithCodeView",
    components: {
        countryCode
    },
    setup() {
        
    },
    data() {
        return {
            dialCode:'',
            isCodeStep: false,
            activityOptions: [],
            formData: {
                code: '',
                organizationName: '',
                organizationId: '',
                organizationDescription: '',
                organizationLogo: '',
                businessType: '',
                country: '',
                service: ''
            },
            errors: {
                email: null,
                lastName: null,
                firstName: null,
                phone: null,
                acceptTerms: null,
                organizationName: null,
                businessType: null,
                code: null
            },
            isSubmit: false,
            isRegister: false,
            isAlert: false,
            isAlertSuccess: true,
            alertMessage: "New message",
            isInvalid: false,
            staticService: [],
            service_id: "",
            serviceoptions:[]
        }
    },
    methods: {
        changeCode (country) {
            //this.dialCode = '(+' + country[2] + ')'
            this.dialCode = '+' + country[2] + ''
            this.formData.country = country[0];
            //this.formData.phoneNumber = this.dialCode  + this.formData.phone;
            console.log(country)
            
            //this.$emit('updateNumber', phone)
        },
        async verifyCode() {
            // Add validation here if needed
            console.log("here")
            if (this.validateStep1()) {
                this.isSubmit = true
                await axios.get(`${process.env.VUE_APP_BASE_URL}organisations/verify_code/${this.formData.code}`)
                .then(response => {
                    console.log(response)
                    if(response.status == 200) {
                        //this.displayError(response.data.message, 'alert-yes', 7000)
                        //this.validatedCode = true;

                        this.isCodeStep = true
                        this.formData.organizationId = response.data.data.id
                        this.formData.organizationName = response.data.data.name
                        this.formData.organizationDescription = "Concepteur de solution tech"
                        this.formData.organizationLogo = response.data.data.logo

                        this.getService(this.formData.organizationId);
                        //this.handleSubmit()
                    }
                    else {
                        //this.displayError('Oups ! something went wrong.', 'alert-no')
                        this.emitAlert("Une erreur s'est produite. Veuillez rechargez la page", 5000)
                        this.isSubmit = false
                    }
                    this.isSubmit = false
                })
                .catch(error => {
                    console.log(error)
                    if (error.response) {
                        //this.displayError(error.response.data.message || 'Code invalide !', 'alert-no', 10000)
                        if (error.response.data.message == "This Organisation is not available") {
                            this.errors.code = "Le code est invalide"
                            this.emitAlert("Organization non disponible", 5000)
                        } else {
                            this.emitAlert("Une erreur s'est produite. Veuillez rechargez la page", 5000)
                        }
                    }
                    else if (error.request) {
                        //this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
                        this.emitAlert("Une erreur s'est produite. Veuillez verifiez votre connexion", 5000)
                    }
                    else {
                        //this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                    }
                    this.isSubmit = false
                })
                
            }
        },
        goBack() {
            this.isCodeStep = false
        },
        async handleSubmit () {
            // Handle form submission
            if (this.validateStep2()) {
                let phoneNumber = this.formData.phone
                this.isSubmit = true
                console.log(this.formData, phoneNumber)
                
                await axios.post(`${process.env.VUE_APP_BASE_URL}accounts/register/add-user-code`,
                    {
                        email: this.formData.email,
                        firstname: this.formData.firstName,
                        lastname: this.formData.lastName,
                        country: this.formData.country,
                        phone: phoneNumber ,
                        service: this.formData.service,
                        code: this.formData.code
                    }
                )
                .then(res => {
                    console.log(res)
                    if(res.status == 201) {
                        this.isRegister = true
                        localStorage.setItem('guest_email', this.formData.email);
                    }
                })
                .catch(err => {
                    console.log(err)
                    if (err.response && !err.response.data.success) {
                        if (err.response.data.data.email == "This email is already in use.") {
                            this.errors.email = "L'email est deja utilise"
                        } else if (err.response.data.message == "You have exceeded the number of users required for your plan") {
                            this.emitAlert("Le nombre d'utilisateurs est excede.", 8000, false)
                        }
                    }
                    this.isSubmit = false
                })
                
            }
        },
        validateStep1() {
            // Add validation logic for step 1
            this.errors = {
                
                code: null
            }
            let isValid = true
            
            if (!this.formData.code || this.formData.code.trim() == "" || this.formData.code.length < 12 ) {
                this.errors.code = 'Le champ code est incorrect.'
                isValid = false
            }

            
            return isValid
        },
        validateStep2() {
            // Add validation logic for step 2
            /*this.errors.organizationName = null
            this.errors.businessType = null
            let isValid = true
            if (!this.formData.organizationName || this.formData.organizationName.trim() == "") {
                this.errors.organizationName = 'Le champ organization est obligatoire.'
                isValid = false
            }
            if (!this.formData.businessType || this.formData.businessType.trim() == "") {
                this.errors.businessType = 'Le champ domain activite est obligatoire.'
                isValid = false
            }*/
            this.errors = {
                email: null,
                lastName: null,
                firstName: null,
                phone: null,
                acceptTerms: null,
                organizationName: null,
                businessType: null
            }
            let isValid = true
            if (!this.formData.email || this.formData.email.trim() == "" ) {
                this.errors.email = 'Le champ email est obligatoire.'
                isValid = false
            } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.formData.email)) {
                this.errors.email = 'L\'adresse email n\'est pas valide.'
                isValid = false
            }
            if (!this.formData.lastName || this.formData.lastName.trim() == "") {
                this.errors.lastName = 'Le champ nom est obligatoire.'
                isValid = false
            }

            if (!this.formData.firstName || this.formData.firstName.trim() == "") {
                this.errors.firstName = 'Le champ prenom est obligatoire.'
                isValid = false
            }
            if (!this.formData.phone || this.formData.phone.trim() == "") {
                this.errors.phone = 'Le champ telephone est obligatoire.'
                isValid = false
            } else if (!this.isNumeric(this.formData.phone)) {
                this.errors.phone = 'Le champ telephone doit contenir uniquement des chiffres.'
                isValid = false
            }
            if (!this.formData.acceptTerms) {
                this.errors.acceptTerms = 'Vous devez cochez les termes et conditions'
                isValid = false
                this.$swal({
                    title: 'Terms et Comditions',
                    text: `Vous devez cochez les termes et conditions`,
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonText: "Fermer"
                });
            }
            return isValid
        },
        getActivity() {
        //this.waitingAPIResponse = true;
        //this.waitingAPIResponse2 = true;
        axios.get(`${process.env.VUE_APP_BASE_URL}activity`)
        .then(response => {
            console.log(response)
            if (response.status === 200) {
                const activities = response.data.results;
                //this.staticActivity = activities;
                this.activityOptions = activities.map(activity => ({
                    value: activity.id,
                    text: activity.name
                }));
            } else {
              //let msg = 'Oups ! something went wrong.';
              //this.alertString = `${msg}|alert-no|5000::` + Date.now();
            }
            /* setTimeout(() => {
                this.waitingAPIResponse = false;
                this.waitingAPIResponse2 = false;
            }, 2000); */
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            //this.alertString = `Activité non disponible|alert-no|10000::` + Date.now();
          } else if (error.request) {
            console.log(error.request)
          } else {
            //let msg = 'Oups ! something went wrong.';
            //this.alertString = `${msg}|alert-no|5000::` + Date.now();
          }
          //this.waitingAPIResponse = false;
          //this.waitingAPIResponse2 = false;
          });
        },
        isNumeric(phoneNumber) {
            return /^[0-9]+$/.test(phoneNumber);
        },
        resentActivationMail () {
            axios.post(`${process.env.VUE_APP_BASE_URL}accounts/resend-email-activate-account/`,
            {
                email: localStorage.guest_email,
            })
            .then(response => {
                if(response.status == 200) {
                    this.emitAlert("Le mail d'activation vous à été envoyé à nouveau. Veuillez consulter votre boite mail.", 8000)
                    this.$emit('resent')
                }
                else {
                    //this.displayError('Oups ! something went wrong.', 'alert-no')
                }
            })
            .catch(error => {
            localStorage.removeItem('token')
            if (error.response && error.response.data) {
                //this.displayError(error.response.data.message, 'alert-no', 10000)
                this.emitAlert(error.response.data.message, 8000, false)
                
            }
            else if (error.request) {
                //this.displayError('The request was made but no response was received. Please check your network.', 'alert-no', 8000)
                this.emitAlert("Une erreur s'est produite", 5000, false)
            }
            else {
                //this.displayError('Oups ! something went wrong.', 'alert-no', 5000)
                this.emitAlert("Une erreur s'est produite", 5000, false)

            }
            this.waitingAPIResponse = false
            })
        },
        getService (idOrganis) {
            
            axios.get(`${process.env.VUE_APP_BASE_URL}services/get_all_service?organisation=${idOrganis}`)
            .then(response => {

                console.log("Id: ", idOrganis)

                if (response.status === 200) {
                    console.log(response.data.data);
                    let services = response.data.data;
                    this.staticService = services;
                    console.log(services);
                    this.serviceoptions = this.staticService.map(activity => ({
                            value: activity.id,
                            text: activity.name
                        }));
                    
                    }
                
                
            })
            .catch(error => {
                    if (error.response) {
                        //this.alertString = `Vous n'appartenez à aucune organisation|alert-no|10000::`+Date.now()
                    }
                    else if (error.request) {
                    //let msg = 'The request was made but no response was received. Please check your network.'
                    //this.alertString = `${msg}|alert-no|8000::`+Date.now()
                    }
                    else {
                    //let msg = 'Oups ! something went wrong.'
                    //this.alertString = `${msg}|alert-no|5000::`+Date.now()
                    }
                    this.waitingAPIResponse = false;
                    this.waitingAPIResponse2 = false;
                })
        },
        emitAlert(text, duration, success = true) {
            this.alertMessage = text 
            this.isAlert = true;
            this.isAlertSuccess = success
            setTimeout (()=> {
                this.isAlert = false
            }, duration)
        }
    },
    mounted () {
        this.getActivity()
    },
    watch: {
        'formData.accountType'(newValue) {
            if (newValue === 'personal') {
                this.isCodeStep = false
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/tailwinds.scss";
    
</style>